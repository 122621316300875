<template>
  <div class="workshops-page">
    <WorkshopsSection />
  </div>
</template>

<script>
import WorkshopsSection from '../components/WorkshopsSection.vue';

export default {
  name: 'WorkshopsPage',
  components: {
    WorkshopsSection
  }
}
</script>

<style scoped>
.workshops-page {
  min-height: 100vh;
  width: 100%;
  padding: 4rem 0;
  background-image: url('../assets/Tegning Christian.jpg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
</style>
