<template>
  <div class="people-page">
    <PeopleSection />
  </div>
</template>

<script>
import PeopleSection from '../components/PeopleSection.vue';

export default {
  name: 'PeoplePage',
  components: {
    PeopleSection
  }
}
</script>

<style scoped>
.people-page {
  min-height: 100vh;
  width: 100%;
  padding: 4rem 0;
  background-image: url('../assets/Tegning Christian.jpg');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
</style>
