<template>
  <section class="workshops" id="workshops">
    <div class="container">
      <h2>Workshops</h2>
      <div class="workshops-grid">
        <div v-for="(workshop, index) in workshops" :key="index" class="workshop-card">
          <div class="date">{{ workshop.date }}</div>
          <h3>{{ workshop.title }}</h3>
          <p class="description">{{ workshop.description }}</p>
          <div class="location">
            <span>{{ workshop.location }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WorkshopsSection',
  data() {
    return {
      workshops: [
        {
          date: 'Coming Soon',
          title: 'Project Workshop',
          description: 'Discussion of research findings and methodologies in asset pricing with heterogeneous investors.',
          location: 'To be announced'
        }
      ]
    }
  }
}
</script>

<style scoped>
.workshops {
  padding: 4rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #1a1a1a;
  margin-bottom: 3rem;
}

.workshops-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.workshop-card {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
}

.workshop-card:hover {
  transform: translateY(-5px);
}

.date {
  display: inline-block;
  background-color: #2c3e50;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-weight: 500;
}

h3 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.description {
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.location {
  color: #666;
  font-style: italic;
}

@media (max-width: 768px) {
  .workshops-grid {
    grid-template-columns: 1fr;
  }
  
  .workshop-card {
    padding: 1.5rem;
  }
}
</style>
