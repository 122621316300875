<template>
  <section class="landing">
    <div class="container">
      <img src="/images/Tegning Christian.jpg" alt="Project Illustration" class="hero-image">
      <div class="content">
        <h1>Asset Pricing with Heterogeneous Investors in Overlapping Generations</h1>
        <p class="subtitle">
          The project "Asset Pricing with Heterogeneous Investors in Overlapping Generations" aims to understand quantitatively the impact of heterogeneity on financial assets and show that it can rationalize the large difference in portfolio holdings, high trading volume, shifts in the cross-sectional consumption and wealth distribution, and the high volatility of asset returns. The project contains two main ingredients: We first develop efficient methods for solving large scale equilibrium models with heterogeneity in disagreement, risk aversion, and time preference. Next, we aim at understanding how disagreement impact the stock and bond markets by estimating equilibrium models with disagreement using survey data.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LandingSection'
}
</script>

<style scoped>
.landing {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  padding: 2rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
}

.hero-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content {
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  font-size: 2.5rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.subtitle {
  font-size: 1.25rem;
  color: #666;
  line-height: 1.6;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  
  .subtitle {
    font-size: 1.1rem;
  }
}

</style>
