<template>
  <nav :class="['navbar', { 'navbar-scrolled': scrolled }]">
    <div class="container">
      <div class="nav-content">
        <router-link to="/" class="nav-brand">Asset Pricing with Heterogeneous Investors in Overlapping Generations</router-link>
        <div class="nav-links">
          <router-link to="/people" class="nav-link">People</router-link>
          <router-link to="/projects" class="nav-link">Projects</router-link>
          <router-link to="/code" class="nav-link">Code</router-link>
          <router-link to="/workshops" class="nav-link">Workshops</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'MainNavigation',
  data() {
    return {
      scrolled: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // Check initial scroll position
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    }
  }
}
</script>

<style scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 1rem 0;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-scrolled {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-brand {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1a1a1a;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-brand:hover {
  color: #2c3e50;
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-link {
  color: #1a1a1a;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  position: relative;
  padding: 0.5rem 0;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #2c3e50;
  transition: width 0.3s ease;
}

.nav-link:hover {
  color: #2c3e50;
}

.nav-link:hover::after {
  width: 100%;
}

@media (max-width: 768px) {
  .nav-content {
    flex-direction: column;
    gap: 1rem;
  }

  .nav-links {
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .nav-link {
    padding: 0.25rem 0;
  }
}
</style>
