<template>
  <div class="projects-page">
    <ProjectsSection />
  </div>
</template>

<script>
import ProjectsSection from '../components/ProjectsSection.vue';

export default {
  name: 'ProjectsPage',
  components: {
    ProjectsSection
  }
}
</script>

<style scoped>
.projects-page {
  min-height: 100vh;
  width: 100%;
  padding: 4rem 0;
  background-image: url('../assets/Tegning Christian.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
</style>
