import { createRouter, createWebHistory } from 'vue-router'
import LandingSection from '../components/LandingSection.vue'
import CodePage from '../views/CodePage.vue'
import PeoplePage from '../views/PeoplePage.vue'
import ProjectsPage from '../views/ProjectsPage.vue'
import WorkshopsPage from '../views/WorkshopsPage.vue'

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingSection
  },
  {
    path: '/code',
    name: 'code',
    component: CodePage
  },
  {
    path: '/people',
    name: 'people',
    component: PeoplePage
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsPage
  },
  {
    path: '/workshops',
    name: 'workshops',
    component: WorkshopsPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
