<template>
  <section class="people" id="people">
    <div class="container">
      <h2>Research Team</h2>
      <div class="team-grid">
        <div v-for="(person, index) in team" :key="index" class="team-member">
          <img :src="person.image" :alt="person.name" class="member-image">
          <h3>{{ person.name }}</h3>
          <p>{{ person.role }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PeopleSection',
  data() {
    return {
      team: [
        {
          name: 'Christian Heyerdahl-Larsen',
          image: '/images/APHIOG_Heyerdahl-Larsen_150.jpg',
          //role: 'Fellow'
        },
        {
          name: 'Paul Ehling',
          image: '/images/APHIOG_Ehling_150.jpg',
          //role: 'Principal investigator'
        },
        // {
        //   name: 'Lochstoer',
        //   image: '/images/APHIOG_Lochstoer_150.jpg',
        //   role: 'Researcher'
        // },
        {
          name: 'Zeshu Xu',
          image: '/images/APHIOG_Xu_150.jpg',
          //role: 'Fellow'
        }
      ]
    }
  }
}
</script>

<style scoped>
.people {
  padding: 4rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #1a1a1a;
  margin-bottom: 3rem;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  justify-items: center;
}

.team-member {
  width: 200px;
  height: 350px;
  text-align: center;
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.team-member:hover {
  transform: translateY(-5px);
}

.member-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 1rem;
  object-fit: cover;
  object-position: center 0%;
}

h3 {
  font-size: 1.25rem;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

p {
  margin: 0;
  color: #666;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .team-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
</style>
