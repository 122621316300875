<template>
  <section class="projects" id="projects">
    <div class="container">
      <h2>Research Papers</h2>
      <div class="papers-grid">
        <div v-for="(paper, index) in papers" :key="index" class="paper-card">
          <h3>{{ paper.title }}</h3>
          <p class="authors">{{ paper.authors }}</p>
          <div class="links">
            <a :href="paper.pdf" class="btn" target="_blank" rel="noopener noreferrer">
              <span>PDF</span>
            </a>
            <a :href="paper.ssrn" class="btn" target="_blank" rel="noopener noreferrer">
              <span>SSRN</span>
            </a>
            <a v-if="paper.slides" :href="paper.slides" class="btn" target="_blank" rel="noopener noreferrer">
              <span>Slides</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProjectsSection',
  data() {
    return {
      papers: [
        {
          title: 'Asset Pricing with Heterogeneous Investors in Overlapping Generations',
          authors: 'Authors to be added',
          pdf: '#',
          ssrn: '#',
          slides: '#'
        }
      ]
    }
  }
}
</script>

<style scoped>
.projects {
  padding: 4rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #1a1a1a;
  margin-bottom: 3rem;
}

.papers-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
}

.paper-card {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.paper-card:hover {
  transform: translateY(-5px);
}

h3 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.authors {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

.links {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.btn {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  background-color: #2c3e50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #1a252f;
}

@media (max-width: 768px) {
  .papers-grid {
    gap: 1.5rem;
  }
  
  .paper-card {
    padding: 1.5rem;
  }
}
</style>
