<template>
  <section class="code" id="code">
    <div class="container">
      <h2>Code Repository</h2>
      <div class="code-grid">
        <div v-for="(repo, index) in repositories" :key="index" class="code-card">
          <h3>{{ repo.name }}</h3>
          <p class="description">{{ repo.description }}</p>
          <div class="links">
            <a :href="repo.github" class="btn" target="_blank" rel="noopener noreferrer">
              <span>View on GitHub</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CodeSection',
  data() {
    return {
      repositories: [
        {
          name: 'Project Code',
          description: 'Implementation of equilibrium models with heterogeneity in disagreement, risk aversion, and time preferences.',
          github: '#'
        }
      ]
    }
  }
}
</script>

<style scoped>

.container {
  position: relative; /* Ensures the container content is on top */
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #1a1a1a;
  margin-bottom: 3rem;
}

.code-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.code-card {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.code-card:hover {
  transform: translateY(-5px);
}

h3 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.description {
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.links {
  display: flex;
  gap: 1rem;
}

.btn {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: #2c3e50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #1a252f;
}

@media (max-width: 768px) {
  .code-grid {
    grid-template-columns: 1fr;
  }
}
</style>
