<template>
  <div class="code-page">
    <CodeSection />
  </div>
</template>

<script>
import CodeSection from '../components/CodeSection.vue';

export default {
  name: 'CodePage',
  components: {
    CodeSection
  }
}
</script>

<style scoped>
.code-page {
  min-height: 100vh;
  width: 100%;
  padding: 4rem 0;
  background-image: url('@/assets/Tegning Christian.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
</style>
